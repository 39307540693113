import VariablesList from '@/vue_components/variables_list/variables_list.js'
import Formatter from '@/services/variables_formatter/variables_formatter.js'
import Translator from '@/services/variables_formatter/translator.js'

const FORMAT = 'format'
const MODE_ORIGINAL = 1
const MODE_VISIBLE = 2
export default class VariablesEditor {
  constructor (options = {}) {
    if (!$(options.container).length) throw new Error('wrong sms editor container')

    const container = options.container
    this.editor = container.find('.template_editor')
    this.variablesButton = container.find('.template_variables_button')
    this.previewButton = container.find('.editor_preview_button').attr('disabled', !this.editor.val())
    this.previewField = container.find('.editor_preview_area')
    this.catSelectorBtn = container.find('.categories_selector_btn')
    this.catSaveBtn = container.find('.categories_save_btn')
    this.templateContainsInvalid = container.find('.editor_has_invalid_variables')
    this.cachedEditorText = ''
    this.previewUrl = options.previewUrl || ''
    this.categoryUrl = options.categoryUrl || ''
    this.categoryType = options.categoryType || ''
    this.templateType = options.templateType || ''
    this.categoriesUrl = options.categoriesUrl || ''
    this.saveTemplateUrl = options.saveTemplateUrl || ''
    this.rewriteTemplateUrl = options.rewriteTemplateUrl || ''
    this.onPreviewChanged = options.onPreviewChanged || function () {}
    this.onPreviewMethodStart = options.onPreviewMethodStart || function () {}
    this.onPreviewMethodEnd = options.onPreviewMethodEnd || function () {}
    this.validationCallback = options.validationCallback || function () {}
    this.availableVariables = options.variables
    this._initFormatter()
    this._VL = this.initializeVariablesList(options.variablesList)
    this._initializeCategoriesSelector(options)
    this._bindEditorEvents()
  }

  originalize () {
    if (this._mode === MODE_ORIGINAL) return this.getText()

    this._mode = MODE_ORIGINAL
    const text = this.getText(MODE_ORIGINAL)
    this.setText(text)

    return text
  }

  visualize () {
    if (this._mode === MODE_VISIBLE) return this.getText()

    this._mode = MODE_VISIBLE
    const text = this.getText(MODE_VISIBLE)
    this.setText(text)

    return text
  }

  initializeVariablesList (options = {}) {
    const params = Object.assign({
      onItemSelected: (object) => {
        this.insertContent(object)
        this.validate()
      },
      labelMethod: (variable) => T.sms.variables_list[variable],
    }, options)

    return new VariablesList(params)
  }

  getText (mode) {
    if (!mode) return this.editor.val()

    return this.formatter.to(FORMAT, this.editor.val(), { mode })
  }

  setText (val) {
    this.editor.val(val).trigger('input')
  }

  setList (vars) {
    this._VL.setList(vars)
  }

  preview () {
    if (this.editor.val() === this.cachedEditorText ||
        !this.validate()) return
    this.previewButton.loadSpin('start')
    this.onPreviewMethodStart()
    $.ajax({
      url: this.previewUrl,
      method: 'POST',
      data: {
        text: this.getText(MODE_ORIGINAL),
      },
      success: (result) => {
        const text = result.text
        this.previewButton.loadSpin('stop')
        this.previewField.html(text)
        this.onPreviewChanged(text)
        this.cachedEditorText = this.editor.val()
        this.onPreviewMethodEnd()
      },
      error: (err) => {
        console.log(err)
        this.onPreviewMethodEnd()
      },
    })
  }

  clearPreview () {
    this.previewField.html('')
    this.cachedEditorText = ''
  }

  validate () {
    const currentList = this._VL.getList()
    const originalText = this.getText(MODE_ORIGINAL)
    const editorVariables = this.formatter.getVariables(originalText)
    const requiredNotPresentList = this._performRequiredVariables(currentList, editorVariables)

    if (requiredNotPresentList.length) {
      const formattedRequiredList = requiredNotPresentList.map((v) => this.format.doFormat(v, { mode: MODE_VISIBLE }))
      Notificator.error(`${T.sms.variables_are_required}: ${formattedRequiredList.join(', ')}`)
    }

    const valid = this._performInvalidVariables(currentList, editorVariables) &&
      requiredNotPresentList.length === 0
    this.validationCallback(valid)

    return valid
  }

  reset () {
    this.templateContainsInvalid.hide()
    this.cachedEditorText = ''
    this.previewField.html('')
    this.setText('')
  }

  _initFormatter () {
    this.translator = new Translator({
      map: T.sms.variables_list,
    })

    this.format = {
      regex: /{{ *(.*?)\.(.*?) *}}/gi,
      doFormat: (variable, params) => {
        switch (params.mode) {
          case MODE_VISIBLE:
            variable = this.translator.translate(variable)
            break
          case MODE_ORIGINAL:
          default:
            variable = this.translator.detranslate(variable)
        }

        return `{{ ${variable.join('.')} }}`
      },
      getVariable: (v1, v2) => [v1, v2],
    }

    this.formatter = new Formatter({
      formats: {
        [FORMAT]: this.format,
      },
    })
  }

  _checkForRequiredVariables (requiredList, editorVars) {
    const requiredNotPresent = []
    for (const requiredVariable of requiredList) {
      const found = editorVars.find((v) => {
        return v[0] === requiredVariable[0] && v[1] === requiredVariable[1]
      })
      if (!found) requiredNotPresent.push(requiredVariable)
    }

    return requiredNotPresent
  }

  _getRequiredList (originalList) {
    const res = []
    for (const item of originalList) {
      for (const child of item.children) {
        if (child.required) res.push([item.source, child.source])
      }
    }

    return res
  }

  _performRequiredVariables (currentList, editorVariables) {
    const requiredList = this._getRequiredList(currentList)

    return this._checkForRequiredVariables(requiredList, editorVariables)
  }

  _performInvalidVariables (currentList, editorVariables) {
    const invalidVariables = []

    for (const editorVariable of editorVariables) {
      // editorVariable = ['client', 'surname', dd]
      let list = currentList
      for (const variable of editorVariable) {
        // variable = 'client'
        const res = list.find((listVariable) => listVariable.source === variable)
        if (res) {
          if (res.children) list = res.children
          else list = []
        } else {
          invalidVariables.push(editorVariable)
          break
        }
      }
    }

    if (!invalidVariables.length) {
      this.templateContainsInvalid.hide()

      return true
    }

    let previewHTML = this.getText(MODE_VISIBLE)
    invalidVariables.forEach((_var) => {
      const translatedVariable = this.format.doFormat(_var, { mode: MODE_VISIBLE })
      previewHTML = previewHTML.replace(new RegExp(translatedVariable, 'g'),
        '<span class="editor_preview_invalid_variable">$&</span>')
    })
    this.previewField.html(previewHTML)
    this.cachedEditorText = ''
    this.templateContainsInvalid.show()

    return false
  }

  _initializeCategoriesSelector (options = {}) {
    const catSelector = options.catSelector !== false
    if (catSelector) {
      this._initializeCategoriesModal()
    } else {
      this.catSelectorBtn.hide()
      this.catSaveBtn.hide()
    }
  }

  _initInsertTemplateModal () {
    const modal = ModalFactory({
      title: T.sms.select_template,
      fullscreenButton: false,
      showButtons: false,
    })

    const body = modal.find('.modal-body')
    this.insertTemplateCatContainer = body
    body.categoriesx({
      url: this.categoryUrl,
      category_type: this.categoryType,
      onClick: (item) => {
        const text = this.formatter.to(FORMAT, item.text, { mode: MODE_VISIBLE })
        this.setText(text)
        modal.modal('hide')
        this.preview()
      },
      filterItems: (item) => {
        if (this.templateType) {
          return item.template_type === this.templateType
        }

        return true
      },
    })

    this.catSelectorBtn.click((e) => modal.modal('show'))
  }

  _initSaveAsTemplateModal () {
    const modal = ModalFactory({
      title: T.sms.save_template,
      fullscreenButton: false,
      id: 'save_as_template_modal',
    })

    const body = modal.find('.modal-body')
    const saveTemplateBtn = modal.find('.modal-save')
    body.append(`<input type="text" class="template_title form-control" 
      placeholder="${t('sms.enter_template_name')}">`)
    body.append('<div class="category_container"></div>')
    const titleInput = body.find('input.template_title')
    const catContainer = body.find('.category_container')
    this.saveAsTemplateCatContainer = catContainer

    catContainer.categoriesx({
      url: this.categoryUrl,
      category_type: this.categoryType,
      filterItems: (item) => {
        if (this.templateType) {
          return item.template_type === this.templateType
        }

        return true
      },
      onClick: (item) => {
        bootbox.confirmYN(`${T.sms.rewrite_template}?`, (res) => {
          if (res) {
            $.ajax({
              url: this.rewriteTemplateUrl(item.id),
              method: 'PATCH',
              data: {
                messages_template: {
                  title: titleInput.val() || item.title,
                  text: this.getText(MODE_ORIGINAL),
                },
              },
              success: (newItem) => {
                modal.modal('hide')
                catContainer.categoriesx('updateItem', newItem, item)
                this.insertTemplateCatContainer.categoriesx('updateItem', newItem, item)
              },
              error (err) {
                console.log(err)
              },
            })
          }
        })
      },
    })

    this.catSaveBtn.click((e) => {
      titleInput.removeClass('has_errors')
      titleInput.val('')
      modal.modal('show')
    })
    saveTemplateBtn.click((e) => {
      saveTemplateBtn.loadSpin('start')

      const template = {
        title: titleInput.val(),
        text: this.getText(MODE_ORIGINAL),
        category_id: catContainer.categoriesx('getCategory').id,
        template_type: this.templateType,
      }
      $.ajax({
        url: this.saveTemplateUrl,
        method: 'POST',
        data: { messages_template: template },
        success: (item) => {
          saveTemplateBtn.loadSpin('stop')
          titleInput.removeClass('has_errors')
          catContainer.categoriesx('addItem', item)
          this.insertTemplateCatContainer.categoriesx('addItem', item)
          modal.modal('hide')
        },
        error (err) {
          console.log(err)
          saveTemplateBtn.loadSpin('stop')
          titleInput.addClass('has_errors')
        },
      })
    })
  }

  _initializeCategoriesModal () {
    this._initInsertTemplateModal()
    this._initSaveAsTemplateModal()
  }

  _bindEditorEvents () {
    this.variablesButton.on('click', () => {
      this._VL.show()
    })

    this.previewButton.on('click', (e) => { this.preview() })

    this.editor.on('paste', (e) => {
      setTimeout(this.validate.bind(this), 0)
    })

    this.editor.on('input focus', (e) => {
      const editorVal = e.target.value.trim()
      this.previewButton.prop('disabled', !editorVal)
      this.catSaveBtn.prop('disabled', !editorVal)
    })
  }

  insertContent (object) {
    const content = this.format.doFormat(object.source, { mode: MODE_VISIBLE })

    const editor = this.editor[0]
    if (editor.selectionStart || editor.selectionStart === 0) {
      const startPos = editor.selectionStart
      const endPos = editor.selectionEnd
      const value = editor.value.substring(0, startPos) +
        content +
        editor.value.substring(endPos, editor.value.length)
      this.setText(value)
    } else {
      const value = editor.value + content
      this.setText(value)
    }
  }
}
