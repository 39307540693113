/* EXAMPLE OF USAGE:
  const curlyFormat = {
    regex: /{{ (.*?) }}/g,
    getVariable: (m1, m2, m3) => m1.split('.'),
    doFormat: (var, params) => `{{ ${var.join('.')} }}`
  }

  const erbFormat = {
    regex: /<%= @(\w)&.(\w) %>/g,
    getVariable: (m1, m2, m3) => [m1, m2],
    doFormat: (var, params) => `<%= @${var[0]}&.${var[1]} %>`
  }

  const formatter = new VariablesFormatter({
    formats: {
      curly: curlyFormat,
      erb: erbFormat
    }
  })

  const exampleText = 'some test string {{ clinic.title }} bla bla {{ user.name }}'
  const result = formatter.to('erb', exampleText, { youCanPassSomeParams: true }) параметры прилетают в doFormat вторым аргументом

  *** result -> 'some test string <%= @clinic&.title %> bla bla <%= @user&.name %>'

  В большинстве случаев лучше использовать 'from' для улучшения производительности, иначе цикл пойдет по всем зарегистрированным форматам
  const result = formatter.from('curly').to('erb', exampleText)
*/

export default class VariablesFormatter {
  constructor (params = {}) {
    this._formatsMap = {}
    if (params.formats) this.registerFormats(params.formats)
  }

  _formatFromName (formatName = '') {
    const format = this._formatsMap[formatName]
    if (!format) throw new Error(`format ${formatName} is not defined in list`)

    return format
  }

  _convert (fromFormat, toFormat, text, params = {}) {
    const result = text.replace(fromFormat.regex, (match, p1, p2, p3) => {
      const variable = fromFormat.getVariable(p1, p2, p3)

      return toFormat.doFormat(variable, params)
    })
    this._fromFormat = null

    return result
  }

  getVariables (text = '') {
    const result = []
    if (this._fromFormat) {
      text.replace(this._fromFormat.regex, (match, p1, p2, p3) => {
        result.push(this._fromFormat.getVariable(p1, p2, p3))

        return match
      })
    } else {
      for (const fName in this._formatsMap) {
        const format = this._formatsMap[fName]
        text.replace(format.regex, (match, p1, p2, p3) => {
          result.push(format.getVariable(p1, p2, p3))

          return match
        })
      }
    }
    this._fromFormat = null

    return result
  }

  registerFormats (formats) {
    for (const formatName in formats) {
      this._formatsMap[formatName] = formats[formatName]
    }
  }

  from (formatName) {
    if (formatName) {
      this._fromFormat = this._formatFromName(formatName)
    }

    return this
  }

  to (toFormatName, text = '', params = {}) {
    const toFormat = this._formatFromName(toFormatName)
    if (this._fromFormat) {
      return this._convert(this._fromFormat, toFormat, text, params)
    }
    let resultText = text
    for (const fName in this._formatsMap) {
      const fromFormat = this._formatsMap[fName]
      resultText = this._convert(fromFormat, toFormat, resultText, params)
    }

    return resultText
  }
}
