export default class Translator {
  constructor (params = {}) {
    this._map = params.map || {}
  }

  translate (variables = []) {
    variables = Array.from(variables)
    let currentObj = this._map
    const res = []
    for (const v of variables) {
      if (typeof currentObj[v] === 'object') {
        if (currentObj[v]['self_translation']) {
          res.push(currentObj[v]['self_translation'])
          currentObj = currentObj[v]
        } else return variables
      } else if (typeof currentObj[v] === 'string') {
        res.push(currentObj[v])
      } else {
        return variables
      }
    }

    return res
  }

  detranslate (variables = []) {
    variables = Array.from(variables)
    let currentObj = this._map
    const res = []

    for (const v of variables) {
      for (const detranslated in currentObj) {
        const tmp = currentObj[detranslated]
        if (typeof tmp === 'object' && tmp['self_translation'] === v) {
          res.push(detranslated)
          currentObj = currentObj[detranslated]
        } else if (typeof tmp === 'string' && tmp === v) {
          res.push(detranslated)
          break
        }
      }
    }

    if (variables.length !== res.length) return variables

    return res
  }
}
